import { render, staticRenderFns } from "./DateInput.vue?vue&type=template&id=1e6b0e06"
import script from "./DateInput.vue?vue&type=script&lang=ts&setup=true"
export * from "./DateInput.vue?vue&type=script&lang=ts&setup=true"
import style0 from "./DateInput.vue?vue&type=style&index=0&id=1e6b0e06&prod&module=true&lang=css"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {DatePicker: require('/app/node_modules/@align/ui/dist/runtime/atoms/DatePicker/DatePicker.vue').default,DropDownItem: require('/app/node_modules/@align/ui/dist/runtime/atoms/DropDown/DropDownItem.vue').default,DropDown: require('/app/node_modules/@align/ui/dist/runtime/atoms/DropDown/DropDown.vue').default,Icon: require('/app/node_modules/@align/ui/dist/runtime/atoms/Icon/Icon.vue').default})
